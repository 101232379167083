import { ViewTypeName } from '@air/api/types';
import { ContextMenu, renderContextMenuItems } from '@air/primitive-context-menu';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { noop } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import LoadMoreSpinner from '~/components/LoadMoreSpinner';
import { PrivateBoardsAndAssetsSelectionBar } from '~/components/PrivateGallery/PrivateBoardsAndAssetsSelectionBar';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { setConfigurableViewFieldsAction } from '~/store/configViews/actions';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useGetPrivateConfigViewOptions } from '~/swr-hooks/gallery/viewFields/useGetPrivateConfigViewsOptions';

export interface PrivateViewProps {
  galleryView: ReactNode;
  tableView: ReactNode;
  kanbanView?: ReactNode;
  contextMenuOptions?: DropdownMenuOption[];
}

/**
 * This is a general component to display private board page data.
 * It calls data requests on mount and on search change
 * It also decides if gallery view or table view should be displayed
 */
export const PrivateView = ({ galleryView, tableView, kanbanView, contextMenuOptions }: PrivateViewProps) => {
  const dispatch = useDispatch();
  const viewTypeName = useSelector(currentViewTypeNameSelector);
  const { data: configViewOptions } = useGetPrivateConfigViewOptions();
  const { currentWorkspace } = useCurrentWorkspace();

  const urlLibraryId = useURLLibraryIdSelector();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const { data: libraryCustomFields } = useLibraryCustomFields({ libraryId: libraryId || urlLibraryId });

  const filteredConfigViewOptions = useMemo(() => {
    if (!!libraryCustomFields?.data.length) {
      return configViewOptions?.data.filter((option) => {
        return (
          !option.customFieldId ||
          !!libraryCustomFields.data.find((field) => field.id === option.customFieldId)?.visible
        );
      });
    }

    return configViewOptions?.data;
  }, [configViewOptions?.data, libraryCustomFields?.data]);

  useEffect(() => {
    if (!!filteredConfigViewOptions?.length) {
      dispatch(setConfigurableViewFieldsAction({ configurableViewFields: filteredConfigViewOptions }));
    }
  }, [dispatch, filteredConfigViewOptions]);

  if (!viewTypeName || !currentWorkspace?.id)
    return (
      <div className="grow">
        <LoadMoreSpinner isVisible isLoading={true} loadMore={noop} />
      </div>
    );

  return (
    <ContextMenu
      trigger={
        <div data-testid="PRIVATE_VIEW" className="grow">
          {viewTypeName === ViewTypeName.gallery && galleryView}
          {viewTypeName === ViewTypeName.table && tableView}
          {viewTypeName === ViewTypeName.kanban && kanbanView}
          <PrivateBoardsAndAssetsSelectionBar />
        </div>
      }
    >
      {renderContextMenuItems({ options: contextMenuOptions })}
    </ContextMenu>
  );
};
