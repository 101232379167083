import { Board, Clip } from '@air/api/types';
import { GallerySection, GalleryViewProps } from '@air/component-gallery-view';
import { useBreakpointsContext } from '@air/provider-media-query';
import { noop } from 'lodash';
import { ComponentType, CSSProperties, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { DropRearrangeArea } from '~/components/DropRearrangeArea/DropRearrangeArea';
import { GalleryItemType } from '~/components/Gallery/types';
import { AssetGalleryCardSize, useGalleryAssets, UseGalleryAssetsParams } from '~/components/Gallery/useGalleryAssets';
import {
  BoardGalleryCardDesktopSize,
  BoardGalleryCardMobileSize,
  UseGalleryBoardParams,
  useGalleryBoards,
} from '~/components/Gallery/useGalleryBoards';
import { FileGalleryCardSize, useGalleryFiles, UseGalleryFilesParams } from '~/components/Gallery/useGalleryFiles';
import LoadMoreSpinner from '~/components/LoadMoreSpinner';
import { PrivateBoardNullState } from '~/components/PrivateBoard/PrivateBoardNullState';
import {
  SelectableGalleryView,
  SelectableGalleryViewProps,
} from '~/components/SelectableGalleryView/SelectableGalleryView';
import { GalleryItemToRearrange } from '~/components/Shared/ClipList/RearrangeableRow';
import DragType, { boardDragTypes, RearrangableItemType } from '~/components/Shared/Drag/dragTypes';
import { ASSET_CARD_ROW_MT } from '~/constants/assetCard';
import { WKSPC_CLIP_INFO_HEIGHT, WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT } from '~/constants/WorkspaceSpacing';
import { useCardSizePreference } from '~/hooks/useCardSizePreference';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useGroupedGallerySelectedItems } from '~/hooks/useGroupedGallerySelectedItems';
import { usePrivateWorkspaceHorizontalPadding } from '~/hooks/usePrivateWorkspaceHorizontalPadding';
import { useRearrangeableGalleryItems } from '~/hooks/useRearrangeableGalleryItems';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import {
  currentSortFieldNameSelector,
  isTitleAndMetadataVisibleSelector,
  visibleCustomFieldsLengthSelector,
} from '~/store/configViews/selectors';
import { usePrivateGalleryViewItems } from '~/swr-hooks/gallery/galleryView/usePrivateGalleryViewItems';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';
import { useAirSelector } from '~/utils/ReduxUtils';
import { getAssetsAndFilesUploads } from '~/utils/Uploader';

const rearrangeTypes = [DragType.asset, DragType.file, ...boardDragTypes];

const usePermissionedVisibleCustomFieldsLength = () => {
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canViewCustomFields = canSeeCustomFields(permissions);

  const visibleCustomFieldsLength = useAirSelector(visibleCustomFieldsLengthSelector);
  const permissionedVisibleCustomFieldsLength = canViewCustomFields ? visibleCustomFieldsLength : 0;

  return { permissionedVisibleCustomFieldsLength };
};

export interface GalleryMetadata {
  isSelectable: boolean;
  type: GalleryItemType;
  itemId: string;
  canDragTo?: (item: GalleryItemToRearrange) => boolean;
}

const style: CSSProperties = {
  paddingBottom: '16rem',
};

export interface PrivateGalleryViewProps {
  renderAsset?: UseGalleryAssetsParams<Clip>['renderAsset'];
  renderFile?: UseGalleryFilesParams<Clip>['renderFile'];
  renderBoard?: UseGalleryBoardParams<Board>['renderBoard'];
  EmptyState?: ComponentType;
  scrollElementRef: SelectableGalleryViewProps['scrollElementRef'];
  showBoards: boolean;
  canRearrange?: boolean;
  showUploads?: boolean;
}

export const PrivateGalleryView = memo(
  ({
    EmptyState = PrivateBoardNullState,
    renderFile,
    renderAsset,
    renderBoard,
    scrollElementRef,
    showBoards,
    showUploads = true,
    canRearrange = false,
  }: PrivateGalleryViewProps) => {
    const [boardsExpanded, setBoardsExpanded] = useState(true);
    const [assetsExpanded, setAssetsExpanded] = useState(true);

    const { isAboveSmallScreen } = useBreakpointsContext();
    const { cardSize } = useCardSizePreference();

    const {
      data: { boards: boardsData, clips: clipsData, files: filesData, uploads },
      isLoading,
      loadNextPage,
      isEmpty,
    } = usePrivateGalleryViewItems({
      showBoards,
      boardsExpanded,
      assetsExpanded,
      showUploads,
    });

    useFetchObjectsPermissions({
      objects: {
        boardIds: boardsData?.items.map(({ id }) => id),
        clipIds: [...clipsData.items, ...filesData.items]?.map(({ id }) => id),
        /**
         * We need to fetch permissions for assets because we use their permissions for certain endpoints
         * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1695838522133639
         */
        assetIds: [...clipsData.items, ...filesData.items]?.map(({ assetId }) => assetId),
      },
    });

    const containerRef = useRef<HTMLDivElement>(null);
    const currentSortFieldName = useAirSelector(currentSortFieldNameSelector);
    const { responsiveHorizontalPadding } = usePrivateWorkspaceHorizontalPadding();
    const isTitleAndMetadataVisible = useAirSelector(isTitleAndMetadataVisibleSelector);
    const { permissionedVisibleCustomFieldsLength } = usePermissionedVisibleCustomFieldsLength();

    const { assetUploads, fileUploads } = useMemo(() => getAssetsAndFilesUploads(uploads || []), [uploads]);

    const { rearrangeItems } = useRearrangeableGalleryItems({
      assets: clipsData?.items ?? [],
      boards: boardsData?.items ?? [],
      files: filesData?.items ?? [],
    });

    useGroupedGallerySelectedItems(boardsData?.items || [], clipsData?.items ?? [], filesData?.items ?? []);

    // add extra space to row for wider drop-rearrange area
    const { width = 0, ref: widthRef } = useResizeDetector<HTMLDivElement>({
      handleHeight: false,
      refreshMode: 'throttle',
      refreshRate: 300,
    });

    const boardsSection = useGalleryBoards({
      data: boardsData,
      containerWidth: width,
      renderBoard,
      loadMore: loadNextPage,
      containerHorizontalPadding: responsiveHorizontalPadding,
      isSelectable: () => true,
      onSectionCollapse: (isCollapsed) => setBoardsExpanded(!isCollapsed),
      itemHeight: isAboveSmallScreen ? BoardGalleryCardDesktopSize[cardSize] : BoardGalleryCardMobileSize[cardSize],
    });

    const fileAdditionalHeight = useMemo(() => {
      const additionalRows = Math.max(0, permissionedVisibleCustomFieldsLength - 2);
      return additionalRows * WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT;
    }, [permissionedVisibleCustomFieldsLength]);

    const assetAdditionalHeight = useMemo(() => {
      let height = 0;
      if (isTitleAndMetadataVisible) height += WKSPC_CLIP_INFO_HEIGHT;
      if (permissionedVisibleCustomFieldsLength) height += ASSET_CARD_ROW_MT;
      return height + permissionedVisibleCustomFieldsLength * WKSPC_CUSTOM_FIELD_DISPLAY_HEIGHT;
    }, [permissionedVisibleCustomFieldsLength, isTitleAndMetadataVisible]);

    const assetsSection = useGalleryAssets({
      renderAsset,
      isFirstSection: !boardsData?.items?.length,
      data: clipsData,
      containerWidth: width,
      uploads: assetUploads,
      loadMore: loadNextPage,
      containerHorizontalPadding: responsiveHorizontalPadding,
      itemAdditionalHeight: assetAdditionalHeight,
      isSelectable: () => true,
      onSectionCollapse: (isCollapsed) => setAssetsExpanded(!isCollapsed),
      itemHeight: AssetGalleryCardSize[cardSize],
    });

    const filesSection = useGalleryFiles({
      renderFile,
      isFirstSection: !boardsData?.items?.length && !clipsData?.items.length,
      data: filesData,
      containerWidth: width,
      uploads: fileUploads,
      loadMore: loadNextPage,
      containerHorizontalPadding: responsiveHorizontalPadding,
      isSelectable: () => true,
      itemHeight: FileGalleryCardSize[cardSize] + fileAdditionalHeight,
    });

    const sections = [boardsSection, assetsSection, filesSection].filter(
      (section): section is GallerySection<GalleryMetadata> => !!section,
    );

    const rowRenderer: Required<GalleryViewProps>['rowRenderer'] = useCallback(
      ({ itemRenderer, style, row, isVisible, index }) => (
        /**
         * index is the index of the row
         * itemIndex is the index of the item in the row
         * item.index is the index of the item in the entire list
         */
        <div style={style} key={index}>
          {row.map((item, itemIndex) => {
            //@ts-ignore
            const type: GalleryItemType = item.getData?.(itemIndex)?.type;

            return (
              <div key={item.index}>
                {itemIndex === 0 && (
                  <DropRearrangeArea
                    dropLocation="asset"
                    onItemRearrange={(draggedItem: RearrangableItemType) => {
                      rearrangeItems(draggedItem, {
                        index: item.index,
                        direction: 'before',
                        type,
                      });
                    }}
                    containerProps={{
                      style: {
                        left: item.box.left - 24,
                        top: 0,
                        height: item.box.height,
                        width: 20,
                        justifyContent: 'center',
                      },
                    }}
                    barProps={{
                      className: 'w-1 h-full',
                    }}
                    dragTypes={rearrangeTypes}
                    canDropItem={(draggedItem) => draggedItem.index !== item.index && canRearrange}
                  />
                )}
                <>{itemRenderer(item, isVisible)}</>
                <DropRearrangeArea
                  dropLocation="asset"
                  onItemRearrange={(draggedItem: RearrangableItemType) => {
                    rearrangeItems(draggedItem, {
                      index: item.index,
                      direction: 'after',
                      type,
                    });
                  }}
                  containerProps={{
                    style: {
                      left: item.box.width + item.box.left + 10,
                      top: 0,
                      height: item.box.height,
                      width: 4,
                    },
                  }}
                  barProps={{
                    className: 'w-1 h-full',
                  }}
                  dragTypes={rearrangeTypes}
                  canDropItem={(draggedItem) => draggedItem.index !== item.index && canRearrange}
                />
              </div>
            );
          })}
        </div>
      ),
      [canRearrange, rearrangeItems],
    );

    return (
      <>
        <div ref={widthRef} />
        {isLoading ? (
          <LoadMoreSpinner isVisible isLoading={isLoading} loadMore={noop} />
        ) : isEmpty ? (
          <EmptyState />
        ) : width ? (
          <div ref={containerRef} className="relative mt-6 min-h-full">
            <SelectableGalleryView
              scrollElementRef={scrollElementRef}
              containerRef={containerRef}
              sections={sections}
              width={width}
              style={style}
              rowRenderer={currentSortFieldName === 'customSort' ? rowRenderer : undefined}
            />
          </div>
        ) : null}
      </>
    );
  },
);

PrivateGalleryView.displayName = 'PrivateGalleryView';
