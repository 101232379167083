import { useBreakpointsContext } from '@air/provider-media-query';
import { useMemo } from 'react';

import { HEADER_DESKTOP_HORIZONTAL_PADDING, HEADER_MOBILE_HORIZONTAL_PADDING } from '~/constants/WorkspaceSpacing';

export function usePrivateTableHeaderPadding() {
  const { isAboveMediumScreen } = useBreakpointsContext();

  const headerHorizontalPadding = useMemo(
    () => (isAboveMediumScreen ? HEADER_DESKTOP_HORIZONTAL_PADDING : HEADER_MOBILE_HORIZONTAL_PADDING),
    [isAboveMediumScreen],
  );

  return { headerHorizontalPadding };
}
