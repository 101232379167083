import { tailwindMerge } from '@air/tailwind-variants';

import { FacePersonThumbnail } from '~/components/FacePersonThumbnail/FacePersonThumbnail';
import { SelectChipRemoveButton } from '~/components/Select/components/SelectChipRemoveButton';
import { PersonOption } from '~/components/Selects/People/utils';
import { formatFullName } from '~/utils/formatFullName';

export interface PersonChipProps {
  person: PersonOption;
  onRemovePerson?: (person: PersonOption) => void;
  className?: string;
}

export const PersonChip = ({
  person,
  person: { firstName, lastName, defaultFace },
  onRemovePerson,
  className,
}: PersonChipProps) => {
  return (
    <div className={tailwindMerge('flex items-center rounded-full py-0.5 pl-0.5 pr-1.5 text-grey-2', className)}>
      <FacePersonThumbnail shape="circle" width={20} height={20} faceThumbnail={defaultFace.thumbnail} />
      <div className="ml-1 mr-0.5 truncate text-12 font-medium text-grey-12">{formatFullName(firstName, lastName)}</div>
      {onRemovePerson && <SelectChipRemoveButton chip={person} onClick={onRemovePerson} />}
    </div>
  );
};
