import {
  BoardAdd,
  BoxLogo,
  BrandfolderLogo,
  Document,
  Dropbox,
  GoogleDrive,
  Import,
  ImportFolder,
} from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { getMenuOption, ImportOptions } from '~/constants/MenuOptions';
import { ImportSourceType } from '~/utils/ImportUtils';

export type getAddMenuOptionsParams = {
  onImportFrom?: (importOption: ImportOptions) => void;
  onAddSubBoard?: () => void;
  onCreateBoard?: () => void;
  onCollectContent?: () => void;
  onUploadFilesFromComputer?: () => void;
  onUploadFoldersFromComputer?: () => void;
  exposeImportSources: ImportSourceType[];
};

export const getAddMenuOptions = ({
  onAddSubBoard,
  onCreateBoard,
  onImportFrom,
  onUploadFilesFromComputer,
  onUploadFoldersFromComputer,
  onCollectContent,
  exposeImportSources,
}: getAddMenuOptionsParams) => {
  const options: DropdownMenuOption[] = [];

  if (onUploadFilesFromComputer) {
    options.push({
      id: 'upload-files',
      label: 'File Upload',
      onSelect: onUploadFilesFromComputer,
      prefix: <Import className="h-4 w-4" />,
      type: 'item',
    });
  }

  if (onUploadFoldersFromComputer) {
    options.push({
      id: 'upload-folders',
      label: 'Folder Upload',
      onSelect: onUploadFoldersFromComputer,
      prefix: <ImportFolder className="h-4 w-4" />,
      type: 'item',
    });
  }

  if (onCollectContent) {
    options.push({ id: 'cc-separator', type: 'separator' });
    options.push({
      id: 'content-collection',
      label: 'Collect Content',
      description: 'Collect content directly from anyone, no Air account needed.',
      onSelect: onCollectContent,
      prefix: <Document className="h-4 w-4" />,
      type: 'item',
    });
  }

  if (onImportFrom) {
    options.push({
      id: 'import-seperator',
      type: 'separator',
    });

    options.push({
      id: 'import-title',
      label: 'Import from...',
      type: 'title',
    });

    exposeImportSources.forEach((source) => {
      const id: ImportOptions = source === 'googleDrive' ? 'import-from-drive' : `import-from-${source}`;
      const option = getMenuOption(id);
      options.push({
        id,
        label: option?.label,
        onSelect: () => onImportFrom(id),
        prefix: getImportLogo(source),
        type: 'item',
      });
    });
  }

  if (onAddSubBoard) {
    options.push({
      id: 'add-sub-board-seperator',
      type: 'separator',
    });

    options.push({
      id: 'add-sub-board',
      label: 'Add Sub-board',
      onSelect: onAddSubBoard,
      prefix: <BoardAdd className="h-4 w-4" />,
      type: 'item',
    });
  }

  if (onCreateBoard) {
    options.push({
      id: 'create-board-seperator',
      type: 'separator',
    });

    options.push({
      id: 'create-new-board',
      label: 'Create new board',
      onSelect: onCreateBoard,
      prefix: <BoardAdd className="h-4 w-4" />,
      type: 'item',
    });
  }

  return options;
};

export const getImportLogo = (source: ImportSourceType) => {
  switch (source) {
    case 'googleDrive':
      return <GoogleDrive className="h-4 w-4" />;
    case 'box':
      return <BoxLogo className="h-4 w-4 text-[#0061D5]" />;
    case 'dropbox':
      return <Dropbox className="h-4 w-4 text-[#0061FF]" />;
    case 'brandfolder':
      return <BrandfolderLogo className="h-4 w-4" />;
    default:
      break;
  }
};
