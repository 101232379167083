import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, memo } from 'react';

export const dropHighlightOffset = -6;

type DropHighlightProps = ComponentProps<'div'>;

const BASE_CLASS_NAME =
  'pointer-events-none absolute box-border rounded-md border-[3px] border-transparent transition-colors duration-150 ease-in group-[.hovering-drop]/droppable-item:border-blue-9 group-[.hovering-drop]/droppable-item:bg-grey-3';

export const DropHighlight = memo(({ className, style, ...props }: DropHighlightProps) => (
  <div
    className={className ? tailwindMerge(BASE_CLASS_NAME, className) : BASE_CLASS_NAME}
    style={{
      left: dropHighlightOffset,
      right: dropHighlightOffset,
      top: dropHighlightOffset,
      bottom: dropHighlightOffset,
      ...style,
    }}
    {...props}
  />
));

DropHighlight.displayName = 'DropHighlight';
