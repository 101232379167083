import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { PeopleFilter, PeopleFilterProps } from '~/components/Filters/PeopleFilter/PeopleFilter';
import {
  clearPeopleFilterValuesAction,
  removeFilterAction,
  setPeopleFilterLogicAction,
  setPeopleFiltersValueAction,
} from '~/store/filters/actions';
import { selectedPeopleFilterLogicSelector, selectedPeopleFiltersSelector } from '~/store/filters/selectors';
import { FilterLogicType, SelectedFilter } from '~/store/filters/types';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export interface PeopleFilterCardProps extends BaseFilterCardProps {
  PeopleSelect: PeopleFilterProps['PeopleSelect'];
}

export const PeopleFilterCard = memo(({ PeopleSelect, ...props }: PeopleFilterCardProps) => {
  const dispatch = useDispatch();

  const clearValues = useCallback(() => {
    dispatch(clearPeopleFilterValuesAction());
  }, [dispatch]);

  const selectedLogic = useSelector(selectedPeopleFilterLogicSelector);
  const selectedPeople = useSelector(selectedPeopleFiltersSelector);

  const onLogicChange = useCallback(
    (logic: FilterLogicType) => dispatch(setPeopleFilterLogicAction({ logic })),
    [dispatch],
  );

  const onSelectedPeopleChanged = useCallback(
    (people: PersonWithDefaultFace[]) => dispatch(setPeopleFiltersValueAction({ people })),
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="people" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <PeopleFilter
        PeopleSelect={PeopleSelect}
        selectedLogic={selectedLogic}
        selectedPeople={selectedPeople}
        onLogicChange={onLogicChange}
        onSelectedPeopleChanged={onSelectedPeopleChanged}
      />
    </FilterCard>
  );
});

PeopleFilterCard.displayName = 'PeopleFilterCard';
