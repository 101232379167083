import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { ImportOptions } from '~/constants/MenuOptions';
import { NEW_ACTION_BUTTON } from '~/constants/testIDs';
import { getImportLogo } from '~/utils/dropdownMenuOptions/getAddMenuOptions';
import { ImportSourceType } from '~/utils/ImportUtils';

import { getDropdownMenuOption } from './getDropdownMenuOption';

interface ActionMenuOptionsParams {
  onImportFrom?: (activePage?: ImportOptions) => void;
  onUploadFilesFromComputer?: () => void;
  onUploadFoldersFromComputer?: () => void;
  onAddSubBoard?: () => void;
  onCreateBoard?: () => void;
  onCollectContent?: () => void;
  exposeImportSources: ImportSourceType[];
}

export const getActionMenuOptions = ({
  onImportFrom,
  onUploadFilesFromComputer,
  onUploadFoldersFromComputer,
  onCreateBoard,
  onAddSubBoard,
  onCollectContent,
  exposeImportSources,
}: ActionMenuOptionsParams) => {
  const options: DropdownMenuOption[] = [];

  if (onUploadFilesFromComputer) {
    options.push({
      ...getDropdownMenuOption('upload-files'),
      id: `${NEW_ACTION_BUTTON}-upload-files`,
      onSelect: onUploadFilesFromComputer,
      type: 'item',
    });
  }

  if (onUploadFoldersFromComputer) {
    options.push(
      {
        ...getDropdownMenuOption('upload-folders'),
        id: `${NEW_ACTION_BUTTON}-upload-folders`,
        onSelect: onUploadFoldersFromComputer,
        type: 'item',
      },
      {
        id: 'upload-folders-separator',
        type: 'separator',
      },
    );
  }

  if (onCollectContent) {
    options.push(
      {
        ...getDropdownMenuOption('content-collection'),
        onSelect: onCollectContent,
        description: 'Collect content directly from anyone, no Air account needed.',
        type: 'item',
      },
      {
        id: 'content-collection-separator',
        type: 'separator',
      },
    );
  }

  if (onImportFrom) {
    options.push({
      id: 'import-title',
      label: 'Import from...',
      type: 'title',
    });

    exposeImportSources.forEach((source) => {
      const id: ImportOptions = source === 'googleDrive' ? 'import-from-drive' : `import-from-${source}`;
      options.push({
        ...getDropdownMenuOption(id),
        prefix: getImportLogo(source),
        id: `${NEW_ACTION_BUTTON}-${source}-import`,
        onSelect: () => onImportFrom(id),
        type: 'item',
      });
    });
  }

  if (onAddSubBoard) {
    if (options.length) {
      options.push({
        id: 'add-sub-board-separator',
        type: 'separator',
      });
    }

    options.push({
      ...getDropdownMenuOption('add-sub-board'),
      id: `${NEW_ACTION_BUTTON}-add-sub-board`,
      onSelect: onAddSubBoard,
      type: 'item',
    });
  }

  if (onCreateBoard) {
    options.push(
      {
        id: 'create-new-board-separator',
        type: 'separator',
      },
      {
        ...getDropdownMenuOption('create-new-board'),
        id: `${NEW_ACTION_BUTTON}-Create new board`,
        onSelect: onCreateBoard,
        type: 'item',
      },
    );
  }

  return options;
};
