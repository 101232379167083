import { ComponentType, memo } from 'react';

import { FilterLogicSelect } from '~/components/Filters/FilterLogicSelect';
import { PeopleSelectProps } from '~/components/Selects/People/PeopleSelect';
import { FilterLogicType } from '~/store/filters/types';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export interface PeopleFilterProps {
  PeopleSelect: ComponentType<Omit<PeopleSelectProps, 'people'>>;
  selectedLogic: any;
  selectedPeople: PersonWithDefaultFace[];
  onLogicChange: (logic: FilterLogicType) => void;
  onSelectedPeopleChanged: (people: PersonWithDefaultFace[]) => void;
}

const peopleLogicOptions: FilterLogicType[] = ['and', 'or'];

export const PeopleFilter = memo(
  ({ PeopleSelect, onLogicChange, onSelectedPeopleChanged, selectedPeople, selectedLogic }: PeopleFilterProps) => {
    return (
      <div>
        <FilterLogicSelect
          logicOptions={peopleLogicOptions}
          selectedLogic={selectedLogic}
          onLogicChange={onLogicChange}
        />
        <PeopleSelect
          hasDropdownOverlay
          size="large"
          isClearable={false}
          alwaysShowClearItemButton
          selectedPeople={selectedPeople}
          onPeopleSelectionChange={onSelectedPeopleChanged}
        />
      </div>
    );
  },
);

PeopleFilter.displayName = 'PeopleFilter';
