import { useMemo } from 'react';

import { useFiltersContext } from '~/providers/FiltersProvider';

export const useAppliedPeopleFilters = () => {
  const {
    filters: { pIdAnd = [], pIdOr = [] },
  } = useFiltersContext();

  const appliedPeopleFilters = useMemo(() => [...pIdAnd, ...pIdOr], [pIdAnd, pIdOr]);

  return { appliedPeopleFilters };
};
