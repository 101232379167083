import { Avatar } from '@air/primitive-avatar';
import { tailwindMerge } from '@air/tailwind-variants';
import { formatFullName, formatInitials } from '@air/utilities';

import { SelectChipRemoveButton } from '~/components/Select/components/SelectChipRemoveButton';
import { UploaderOption } from '~/components/Selects/Uploader/utils';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export interface UploaderChipProps {
  uploader: UploaderOption;
  className?: string;
  onRemoveUploader?: (uploader: UploaderOption) => void;
}

export const UploaderChip = ({
  uploader,
  uploader: { id, avatar, firstName, lastName },
  className,
  onRemoveUploader,
}: UploaderChipProps) => {
  return (
    <div className={tailwindMerge('flex items-center rounded-full bg-grey-3 p-0.5 pr-1.5', className)}>
      <Avatar
        appearance="circle"
        size={20}
        src={getAvatarImageSrc(avatar, 20)}
        text={formatInitials(firstName, lastName)}
        colorSeed={id}
      />
      <div className="ml-1 mr-0.5 truncate text-12 font-medium text-grey-12">{formatFullName(firstName, lastName)}</div>
      {onRemoveUploader && <SelectChipRemoveButton chip={uploader} onClick={onRemoveUploader} />}
    </div>
  );
};
