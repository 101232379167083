import { memo } from 'react';

import { BaseFilterCardProps } from '~/components/Filters/FilterCard';
import { PrivatePeopleSelect } from '~/components/Selects/People/PrivatePeopleSelect';

import { PeopleFilterCard } from './PeopleFilterCard';

export const PrivatePeopleFiltersCard = memo((props: BaseFilterCardProps) => (
  <PeopleFilterCard PeopleSelect={PrivatePeopleSelect} {...props} />
));

PrivatePeopleFiltersCard.displayName = 'PrivatePeopleFiltersCard';
