import { useBreakpointsContext } from '@air/provider-media-query';
import { Upload } from '@air/redux-uploader';
import { isEqual } from 'lodash';
import React, { CSSProperties, memo, useCallback, useMemo } from 'react';

import { MobileUploadTableRow } from '~/components/TableView/MobileTableRow/MobileUploadTableRow';
import { UploadNameCell } from '~/components/TableView/NameCell/UploadNameCell';
import { TableCell } from '~/components/TableView/TableCell';
import { ConfigurableTableCells } from '~/components/TableView/TableRow/ConfigurableTableCells';
import { DesktopTableRowContainer } from '~/components/TableView/TableRow/DesktopTableRowContainer';
import { TableRow } from '~/components/TableView/TableRow/TableRow';
import { getTableColumnWidth } from '~/components/TableView/types';
import { uploadTableRowInfo } from '~/components/TableView/utils';

export interface UploadTableRowProps {
  upload: Upload;
  /**
   * `react-virtualized` warns that `style` property should be included in the rendered cell for positioning
   * so we've separated it from the `tx` prop
   * */
  style: CSSProperties;
}

export const UploadTableRow = memo(({ upload, style }: UploadTableRowProps) => {
  const uploadRowInfo = useMemo(() => uploadTableRowInfo(upload), [upload]);
  const { isAboveMediumScreen } = useBreakpointsContext();
  const renderCustomFieldTableCellComponent = useCallback(
    () => <TableCell width={getTableColumnWidth('customField')} />,
    [],
  );

  return (
    <>
      {isAboveMediumScreen ? (
        <DesktopTableRowContainer style={style}>
          <TableRow>
            <UploadNameCell upload={upload} />
            <ConfigurableTableCells
              renderCustomFieldTableCellComponent={renderCustomFieldTableCellComponent}
              rowInfo={uploadRowInfo}
            />
          </TableRow>
        </DesktopTableRowContainer>
      ) : (
        <MobileUploadTableRow upload={upload} style={style} />
      )}
    </>
  );
}, isEqual);

UploadTableRow.displayName = 'UploadTableRow';
