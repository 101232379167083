import { Board } from '@air/api/types';
import { useEffect, useRef } from 'react';

import { usePrivateFiltersEntitiesDetails } from '~/hooks/filters/usePrivateFiltersEntitiesDetails';
import { useSetFiltersFromContextToRedux } from '~/hooks/filters/useSetFiltersFromContextToRedux';
import { useGetPrivateFilterOptions } from '~/swr-hooks/filters/useGetPrivateFilterOptions';

export const useSetPrivateFiltersFromContextToRedux = (boardId?: Board['id']) => {
  const firstChecked = useRef(false);

  const { data: options } = useGetPrivateFilterOptions(boardId);
  const { data: entities } = usePrivateFiltersEntitiesDetails();

  const { setFiltersFromContextToRedux } = useSetFiltersFromContextToRedux({
    options,
    entities,
  });

  useEffect(() => {
    if (!!options && !!entities && !firstChecked.current) {
      firstChecked.current = true;
      setFiltersFromContextToRedux();
    }
  }, [options, entities, setFiltersFromContextToRedux]);

  return {
    setFiltersFromContextToRedux,
  };
};
