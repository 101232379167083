import { memo, useCallback, useMemo } from 'react';

import { Uploader } from '~/components/Filters/UploaderFilter/UploaderFilterCard';
import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { Select, SelectProps } from '~/components/Select/Select';
import { UploaderChip } from '~/components/Selects/Uploader/UploaderChip';
import { convertUploaderToOption, UploaderOption } from '~/components/Selects/Uploader/utils';
import { UPLOADER_SELECT } from '~/constants/testIDs';

export interface UploaderSelectProps
  extends Omit<SelectProps<UploaderOption>, 'options' | 'selectedOptions' | 'onSelectionChange'> {
  uploaders: Uploader[];
  selectedUploaders: Uploader[];
  onUploaderSelectionChange: (newUploaders: Uploader[]) => void;
}

export const UploaderSelect = memo(
  ({
    uploaders,
    selectedUploaders,
    onUploaderSelectionChange,
    placeholder = 'Search workspace members',
    ...props
  }: UploaderSelectProps) => {
    const options: UploaderOption[] = useMemo(() => uploaders.map((u) => convertUploaderToOption(u)), [uploaders]);

    const selectedOptions = useMemo(
      () => selectedUploaders.map((u) => convertUploaderToOption(u)),
      [selectedUploaders],
    );

    const onSelectionChange: SelectProps<UploaderOption>['onSelectionChange'] = useCallback(
      ({ chips }) => {
        onUploaderSelectionChange(chips);
      },
      [onUploaderSelectionChange],
    );

    const onUploaderDeselect = useCallback(
      (uploader: Uploader) => {
        onUploaderSelectionChange(selectedUploaders.filter((u) => u.id !== uploader.id));
      },
      [onUploaderSelectionChange, selectedUploaders],
    );

    const listItemRenderer = useCallback<Required<SelectProps<UploaderOption>>['listItemRenderer']>(
      (uploader) => (
        <SelectChipOptionContainer className="-mx-0.5 py-2">
          <UploaderChip uploader={uploader} className="bg-transparent" />
        </SelectChipOptionContainer>
      ),
      [],
    );

    const selectedItemRenderer = useCallback<Required<SelectProps<UploaderOption>>['chipRenderer']>(
      (uploader) => <UploaderChip uploader={uploader} className="m-0.5" onRemoveUploader={onUploaderDeselect} />,
      [onUploaderDeselect],
    );

    return (
      <Select
        data-testid={UPLOADER_SELECT}
        options={options}
        maxDropdownHeight={300}
        onSelectionChange={onSelectionChange}
        selectedOptions={selectedOptions}
        renderAsInput
        isSearchable
        isSticky
        placeholder={placeholder}
        chipRenderer={selectedItemRenderer}
        listItemRenderer={listItemRenderer}
        {...props}
      />
    );
  },
);

UploaderSelect.displayName = 'UploaderSelect';
