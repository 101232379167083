import { useAppliedCustomFieldsFilters } from '~/hooks/filters/useAppliedCustomFieldsFilters';
import { useAppliedPeopleFilters } from '~/hooks/filters/useAppliedPeopleFilters';
import { useAppliedTagsFilters } from '~/hooks/filters/useAppliedTagsFilters';
import { useGetPrivateFiltersEntities } from '~/swr-hooks/filters/useGetPrivateFiltersEntities';

/**
 * This hook is used to get the details of applied filters entities:
 * - tags
 * - custom fields
 * - people
 */
export const usePrivateFiltersEntitiesDetails = () => {
  const { appliedTagsFilters } = useAppliedTagsFilters();
  const { appliedCustomFieldsFilters } = useAppliedCustomFieldsFilters();
  const { appliedPeopleFilters } = useAppliedPeopleFilters();

  return useGetPrivateFiltersEntities({
    tagLabels: appliedTagsFilters,
    customFields: appliedCustomFieldsFilters,
    personIds: appliedPeopleFilters,
  });
};
