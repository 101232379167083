import { DefaultChipType } from '~/components/Select/shared/types';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';
import { formatFullName } from '~/utils/formatFullName';

export interface PersonOption extends PersonWithDefaultFace, DefaultChipType {}

export const convertPersonToOption = (person: PersonWithDefaultFace): PersonOption => ({
  ...person,
  value: person.id,
  label: formatFullName(person.firstName, person.lastName) ?? '',
});
