import { Clip } from '@air/api/types';
import classnames from 'classnames';
import { CSSProperties, ReactNode, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

import { useAssetPermissionsCache } from '~/hooks/useAssetPermissionsCache';
import { selectedBoardsSelector, selectedClipsAssetIdsSelector } from '~/store/selectedItems/selectors';
import { canMergeAsset } from '~/utils/permissions/assetPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import { checkIfCanMoveAssets } from './DNDParentBoard/methods';
import dragTypes, { DropData, DropLocation } from './dragTypes';

export interface DNDParentAssetProps {
  asset: Clip;
  children: ReactNode;
  dropLocation: DropLocation;
  style?: CSSProperties;
}

export const DNDParentAsset = ({ asset, children, dropLocation, style }: DNDParentAssetProps) => {
  const [canDrop, setCanDrop] = useState(false);
  const store = useAirStore();
  const selectedAssetsIds = selectedClipsAssetIdsSelector(store.getState());
  const selectedBoards = selectedBoardsSelector(store.getState());
  const { getAssetPermissions } = useAssetPermissionsCache();

  const checkIfCanDrop = (monitor: DropTargetMonitor) => {
    // this comes from the `getItem` of DNDMovableToBoardItem
    const item = monitor.getItem();

    const permissions = getAssetPermissions(asset.assetId);

    if (!canMergeAsset(permissions)) {
      return false;
    }

    if ((item.type === 'asset' || item.type === 'file' || selectedAssetsIds.length) && !selectedBoards.length) {
      return checkIfCanMoveAssets([...selectedAssetsIds, item.assetId], asset.assetId);
    } else {
      return false;
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: [dragTypes.asset, dragTypes.file],
    drop: (): DropData => ({
      type: 'move',
      location: dropLocation,
      data: asset,
    }),
    hover: (_props, monitor) => {
      const canDropItem = checkIfCanDrop(monitor);
      if (canDropItem !== canDrop) {
        setCanDrop(canDropItem);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    canDrop: (_props, monitor) => checkIfCanDrop(monitor),
  });

  return (
    <div
      data-draggable="true"
      className={classnames('group/droppable-item h-full', {
        'hovering-drop': isOver && canDrop,
      })}
      style={{ position: 'relative', ...style }}
      ref={drop}
    >
      {children}
    </div>
  );
};
