import { memo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { PeopleSelect, PeopleSelectWrapperProps } from '~/components/Selects/People/PeopleSelect';
import { useGetPrivatePeopleList } from '~/swr-hooks/person/useGetPrivatePeopleList';

type PrivatePeopleSelectProps = PeopleSelectWrapperProps;

export const PrivatePeopleSelect = memo((props: PrivatePeopleSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 200, {
    trailing: true,
  });

  const { data: people, isLoading: isInitialLoading } = useGetPrivatePeopleList({
    search: debouncedSearchValue,
    peopleType: 'named',
    limit: 10,
  });

  return (
    <PeopleSelect
      people={people}
      data-testid="PEOPLE_SELECT"
      placeholder="Search for people"
      isLoading={isInitialLoading}
      onInputChange={setSearchValue}
      {...props}
    />
  );
});

PrivatePeopleSelect.displayName = 'PrivatePeopleSelect';
