import { BaseCustomField, Clip } from '@air/api/types';
import { isEqual } from 'lodash';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { GalleryViewRender } from '~/components/Gallery/types';
import { DNDAssetGalleryCard } from '~/components/PrivateGallery/DNDAssetGalleryCard';
import { QueryParamNames } from '~/constants/search';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { useGetAvailableCustomFields } from '~/hooks/useGetAvailableCustomFields';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useOpenAssetInNewTab } from '~/hooks/useOpenAssetInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { canSeeAssetCustomFields, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { useGetPrivateAssetMenuOptionsCallback } from './useGetPrivateAssetMenuOptionsCallback';

type PrivateGalleryAssetProps = GalleryViewRender<Clip>;

export const PrivateGalleryAsset = memo(
  ({ data: asset, box, index }: PrivateGalleryAssetProps) => {
    const { clipPermissions } = useClipPermissions({
      clipId: asset.id,
    });
    const { customFields } = usePrivateCustomFieldsContext();
    const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
    const { getAssetMenuOptionsCallback } = useGetPrivateAssetMenuOptionsCallback();
    const _getItemMenuOptions = getAssetMenuOptionsCallback({ item: asset });
    const { goToAssetPage } = useGoToAssetPage();
    const { openAssetInNewTab } = useOpenAssetInNewTab();
    const urlLibraryId = useURLLibraryIdSelector();
    const libraryId = useSelector(centralizedBoardLibraryIdSelector);
    const { getAvailableCustomFields } = useGetAvailableCustomFields<BaseCustomField>({
      libraryId: libraryId || urlLibraryId,
    });

    const onCustomFieldsClick = useCallback(
      () =>
        goToAssetPage({
          asset,
          query: {
            [QueryParamNames.tab]: AssetModalPanel.FIELDS,
          },
          trackLocation: 'custom-field-click',
        }),
      [asset, goToAssetPage],
    );

    const onDiscussionsClick = useCallback(
      () =>
        goToAssetPage({
          asset,
          query: {
            [QueryParamNames.tab]: AssetModalPanel.COMMENTS,
          },
          trackLocation: 'comments-click',
        }),
      [asset, goToAssetPage],
    );

    const onVersionsClick = useCallback(
      () =>
        goToAssetPage({
          asset,
          query: {
            [QueryParamNames.versions]: true,
          },
          trackLocation: 'versions-click',
        }),
      [asset, goToAssetPage],
    );

    const onItemClick = useCallback(
      () =>
        goToAssetPage({
          asset,
          trackLocation: 'card-click',
        }),
      [asset, goToAssetPage],
    );

    const onItemCmdClick = useCallback(
      () =>
        openAssetInNewTab({
          asset,
          trackLocation: 'card-click',
        }),
      [asset, openAssetInNewTab],
    );

    const canViewCustomFields = canSeeAssetCustomFields(clipPermissions);
    const canSeeDiscussions = canViewAssetDiscussions(clipPermissions);

    return (
      <DNDAssetGalleryCard
        cardWidth={box.width}
        clip={asset}
        enableDrag={!isMobileAgent}
        index={index}
        isSelectable={true}
        key={asset.id}
        onAssetClick={onItemClick}
        onAssetTitleCmdClick={onItemCmdClick}
        onCustomFieldsClick={onCustomFieldsClick}
        onDiscussionsClick={onDiscussionsClick}
        onVersionsClick={onVersionsClick}
        showVersionsIndicator={!!asset.assetVersionCount && asset?.assetVersionCount > 1}
        getItemMenuOptions={_getItemMenuOptions}
        getSelectionMenuOptions={getSelectionMenuOptions}
        canViewCustomFields={canViewCustomFields}
        allCustomFields={getAvailableCustomFields(customFields)}
        shouldShowDiscussions={canSeeDiscussions}
      />
    );
  },
  // `container` comes from `react-virtualized` and is a different object every time but may have the same values so we need to deep equal check the props
  isEqual,
);

PrivateGalleryAsset.displayName = 'PrivateGalleryAsset';
