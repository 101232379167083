import { memo, useCallback, useMemo } from 'react';

import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { Select, SelectProps } from '~/components/Select/Select';
import { PersonChip } from '~/components/Selects/People/PersonChip';
import { convertPersonToOption, PersonOption } from '~/components/Selects/People/utils';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export interface PeopleSelectProps
  extends Omit<SelectProps<PersonOption>, 'options' | 'selectedOptions' | 'onSelectionChange'> {
  people: PersonWithDefaultFace[];
  selectedPeople: PersonWithDefaultFace[];
  onPeopleSelectionChange: (newPeople: PersonWithDefaultFace[]) => void;
}

export type PeopleSelectWrapperProps = Omit<PeopleSelectProps, 'people' | 'isLoading' | 'onInputChange'>;

export const PeopleSelect = memo(
  ({
    people,
    selectedPeople,
    onPeopleSelectionChange,
    placeholder = 'Search workspace members',
    ...props
  }: PeopleSelectProps) => {
    const options: PersonOption[] = useMemo(() => people.map((u) => convertPersonToOption(u)), [people]);

    const selectedOptions = useMemo(() => selectedPeople.map((u) => convertPersonToOption(u)), [selectedPeople]);

    const onSelectionChange: SelectProps<PersonOption>['onSelectionChange'] = useCallback(
      ({ chips }) => {
        onPeopleSelectionChange(chips);
      },
      [onPeopleSelectionChange],
    );

    const onPersonDeselect = useCallback(
      (person: PersonWithDefaultFace) => {
        onPeopleSelectionChange(selectedPeople.filter((u) => u.id !== person.id));
      },
      [onPeopleSelectionChange, selectedPeople],
    );

    const listItemRenderer = useCallback<Required<SelectProps<PersonOption>>['listItemRenderer']>(
      (person) => (
        <SelectChipOptionContainer className="-mx-0.5 py-2">
          <PersonChip person={person} />
        </SelectChipOptionContainer>
      ),
      [],
    );

    const selectedItemRenderer = useCallback<Required<SelectProps<PersonOption>>['chipRenderer']>(
      (person) => <PersonChip person={person} className="m-0.5 bg-grey-3" onRemovePerson={onPersonDeselect} />,
      [onPersonDeselect],
    );

    return (
      <Select
        data-testid="PERSON_SELECT"
        options={options}
        maxDropdownHeight={300}
        onSelectionChange={onSelectionChange}
        selectedOptions={selectedOptions}
        renderAsInput
        isSearchable
        isSticky
        placeholder={placeholder}
        chipRenderer={selectedItemRenderer}
        listItemRenderer={listItemRenderer}
        {...props}
      />
    );
  },
);

PeopleSelect.displayName = 'PeopleSelect';
